import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import Container from "../../components/Container";

import {
  Layout,
  FeatureGridWithOffsetIcons,
  CTAAreYouReady,
  ContractingProcess,
  LinkButton,
  SEO,
} from "../../components";

import {
  ComputerDesktopIcon,
  CogIcon,
  ArrowPathIcon,
  BanknotesIcon,
  CheckIcon,
  XMarkIcon,
  ShieldCheckIcon,
  HeartIcon,
  CurrencyDollarIcon,
  PencilIcon,
  LockClosedIcon,
  NewspaperIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import Testimonials from "../../components/Testimonials";

const benefits = [
  {
    name: "Meses Sin Intereses",
    description: "Financiamiento accesible con plazos de 6 a 48 meses.",
    icon: CurrencyDollarIcon,
  },
  {
    name: "Beneficios fiscales",
    description: "Deduce el 100% del ISR del equipo en el primer año.",
    icon: NewspaperIcon,
  },
  {
    name: "Diseño Personalizado",
    description: "Basado en tus consumos, ubicación y el espacio en tu techo.",
    icon: PencilIcon,
  },
  {
    name: "Transparencia completa",
    description: "Sin costos ocultos ni complicaciones.",
    icon: LockClosedIcon,
  },
  {
    name: "Trámite sencillo",
    description: "Aprobaciones rápidas con requisitos mínimos.",
    icon: StarIcon,
  },
  {
    name: "Instalación incluida",
    description:
      "Nos aliamos con las mejores cuadrillas de instaladores en cada ciudad para asegurar que tu sistema esté en óptimas condiciones.",
    icon: CogIcon,
  },
];

const comparations = [
  {
    title: "CFE",
    featured: false,
  },
  {
    title: "Suscripción Bright",
    featured: true,
  },
  {
    title: "Prepago Bright",
    featured: false,
  },
];

const features = [
  {
    title: "Cero Inversión",
    tiers: [
      { title: "CFE", value: true },
      { title: "Suscripción", featured: true, value: true },
      { title: "Prepago", value: false },
    ],
  },
  {
    title: "Mantenimiento sin costo",
    tiers: [
      { title: "CFE", value: false },
      { title: "Suscripción", featured: true, value: true },
      { title: "Prepago", value: true },
    ],
  },
  {
    title: "Garantía de producto",
    tiers: [
      { title: "CFE", value: false },
      { title: "Suscripción", featured: true, value: true },
      { title: "Prepago", value: true },
    ],
  },
  {
    title: "Garantía de producción con reembolso",
    tiers: [
      { title: "CFE", value: false },
      { title: "Suscripción", featured: true, value: true },
      { title: "Prepago", value: true },
    ],
  },
  {
    title: "Seguro de instalación",
    tiers: [
      { title: "CFE", value: false },
      { title: "Suscripción", featured: true, value: true },
      { title: "Prepago", value: true },
    ],
  },
  {
    title: "Eres dueño del sistema",
    tiers: [
      { title: "CFE", value: false },
      { title: "Suscripción", featured: true, value: false },
      { title: "Prepago", value: false },
    ],
  },
];

const ResidentialPage = ({ data }) => {
  const brightSteps = getImage(data.brightSteps.childImageSharp);

  return (
    <Layout>
      <SEO
        title="Energía solar sin inversión para tu hogar"
        description="Bright Hogar es un servicio premium de energía solar por suscripción y prepago en México."
      />
      {/* Hero Start */}
      <Container className="py-8 bg-white">
        <div className="flex w-full lg:w-1/2 justify-center lg:justify-start items-center px-6 text-center lg:text-left">
          <div>
            <h1 className="text-3xl sm:text-2xl text-blue-500 font-bold">
              <span className="block">
                Energía solar para tu hogar al alcance de todos
              </span>
              <span className="text-3xl sm:text-4xl block text-yellow-500 mt-1 font-bold uppercase">
                Con pagos flexibles
              </span>
            </h1>
            <p className="mt-3 md:mt-5 text-lg md:text-lg text-gray-700">
              Con Bright, financia tu sistema fotovoltaico con cuotas accesibles
              y <b>Meses Sin Intereses</b>. Ahorra en energía desde el primer
              mes y aprovecha beneficios fiscales.
            </p>
            <div className="flex flex-col mx-auto md:flex-row mt-8 justify-center lg:justify-start space-y-6 md:space-y-0 md:space-x-6">
              <LinkButton shadow arrow size="large" to="/cotizar">
                Solicita tu cotización
              </LinkButton>
            </div>
          </div>
        </div>
        <div className="flex w-full lg:w-1/2 items-center px-8 mt-8 lg:mt-0 -z-0">
          <StaticImage
            alt="instalacion con flores"
            height={450}
            src="../images/instalaciones-3.jpg"
          />
        </div>
      </Container>
      {/* Hero End */}

      <FeatureGridWithOffsetIcons
        title="Beneficios de adquirir paneles solares con Bright"
        features={benefits}
      />

      <Container className="py-16 bg-white">
        <div className="flex flex-col">
          <h2 className="mt-2 text-3xl font-bold text-blue-500 text-center uppercase">
            ¿Qué dicen nuestros clientes?
          </h2>
          <Testimonials />
        </div>
      </Container>

      <ContractingProcess image={brightSteps} />

      <CTAAreYouReady />
    </Layout>
  );
};
export default ResidentialPage;

export const query = graphql`
  query {
    brightSteps: file(
      relativePath: { eq: "illustrations/4_steps_bright.png" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 700)
      }
    }
  }
`;
